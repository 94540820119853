"use client"
import styles from "./InsightItem.module.scss"
import Image from "next/image"
import { getDate } from "@/utils/datehelpers.js"

export default function InsightItem({
  item,
  openVideo,
  isSlider,
  className = "",
  isMain = false,
  withImg = true,
  postsPage = false,
  isNextLink = false,
  withPlay = false,
  isVideo = false,
  fullView = false,
}) {
  const isPlay = item?.is_video_article || withPlay

  const textSize = 50

  const formatText = (el) => {
    return el.length > textSize ? `${el.slice(0, textSize)}...` : el
  }

  const InsightItemContent = () => {
    return (
      <a
        href={!isVideo ? `/posts/${item.full_url_slug}/` : null}
        className={`${postsPage ? styles.InsightItem_posts : ""} ${
          isNextLink ? styles.InsightItem_next : ""
        }`}
      >
        <div
          className={`${styles.InsightItem__imgwrap} ${
            isMain ? styles.main : ""
          } `}
        >
          {isPlay && !isMain && !fullView && (
            <Image
              className={styles.InsightItem__icon}
              src="/images/insights/icons/play.svg"
              alt="play"
              width={isMain || !withImg ? 56 : 32}
              height={isMain || !withImg ? 56 : 32}
            />
          )}

          {(item.image || item.image_webp) && withImg ? (
            <Image
              className={styles.InsightItem__img}
              src={item.image_webp ? item.image_webp : item.image}
              alt={item.title || "past event video"}
              width={isMain ? 648 : 152}
              height={isMain ? 363 : 88}
            />
          ) : (
            <Image
              className={styles.InsightItem__img}
              src="/images/posts/image-placeholder.jpg"
              width={isMain ? 648 : 152}
              height={isMain ? 363 : 88}
              alt={item.title || "past event video"}
            />
          )}
        </div>

        {item.title && (
          <div className={styles.InsightItem__contentwrap}>
            {fullView && isPlay && (
              <Image
                className={styles.InsightItem__icon}
                src="/images/insights/icons/play.svg"
                alt="play"
                width={isMain || !withImg ? 56 : 32}
                height={isMain || !withImg ? 56 : 32}
              />
            )}
            {!isMain && (
              <div className={styles.InsightItem__desc}>
                {item.created_at && !isNextLink && !fullView && (
                  <span className={styles.InsightItem__date}>
                    {getDate(item.created_at)}
                  </span>
                )}
                {isNextLink && (
                  <span className={styles.InsightItem__nexttitle}>
                    NEXT ARTICLE
                  </span>
                )}

                <h4 className={styles.InsightItem__title}>
                  {formatText(item.title)}
                </h4>
              </div>
            )}

            {isMain && isPlay && (
              <Image
                className={styles.InsightItem__icon}
                src="/images/insights/icons/play.svg"
                alt="play"
                width={isMain || !withImg ? 56 : 32}
                height={isMain || !withImg ? 56 : 32}
              />
            )}
            {isMain && (
              <div className={styles.InsightItem__text}>{item.title}</div>
            )}
          </div>
        )}
      </a>
    )
  }

  return item ? (
    <div
      className={`${styles.InsightItem} ${
        isMain ? styles.InsightItem__main : ""
      }
      ${fullView ? styles.InsightItem__full : ""}
      ${isSlider ? styles.InsightItem_slideritem : ""}
      ${!withImg ? styles.InsightItem_onlyicon : ""}
      ${className}`}
      onClick={openVideo ? openVideo : () => {}}
    >
      <InsightItemContent></InsightItemContent>
    </div>
  ) : null
}
